import { breakpoints } from 'src/styles/breakpoints'
import { graphite, gray } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  p {
    height: 100px;
  }

  .black-card {
    top: -15px;
    position: relative;
  }

  a {
    @media (min-width: ${breakpoints.md}){
      max-width: 336px;
    }
    @media (min-width: ${breakpoints.lg}){
      max-width: 397px;
    }
    @media (min-width: ${breakpoints.xl}){
      max-width: 442px;
    }
  }

  .bg-card-platinum {
    background-color: ${gray};
  }
  .bg-credit-card {
    background-color: ${graphite};
  }
  .bg {
    @media (min-width: ${breakpoints.md}) {
      background-image: linear-gradient(to left, #3C3331 0, #3C3331 50%, #EBEBEB 50%);
    }
  }
`
